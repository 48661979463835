<template>
  <nav class="sidebar">
    <ul class="nav_list">
      <li
        class="nav"
        v-for="nav in navList"
        :key="nav.image"
        :class="{ selected: $route.name === nav.routeName }"
      >
        <router-link :to="`/app/${nav.link}`" v-if="nav.routeName !== '#'">
          <SVGComponent :name="nav.image" />
          <p>{{ nav.name }}</p>
        </router-link>
        <a v-else :href="nav.link" target="_blank">
          <SVGComponent :name="nav.image" />
          <p>{{ nav.name }}</p>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import SVGComponent from '@/components/common/SVGComponent';

export default {
  components: {
    SVGComponent
  },
  data() {
    return {
      navList: [
        {
          name: '상품 수집 설정',
          routeName: '상품수집설정',
          link: 'productSetting',
          image: 'collectSetting'
        },
        {
          name: '상품 수집 관리',
          routeName: '상품수집관리',
          link: 'ProductManagement',
          image: 'collectManagement'
        },
        {
          name: '대기 상품 관리',
          routeName: '대기상품관리',
          link: 'standbyProduct',
          image: 'standbyProduct'
        },
        {
          name: '등록 상품 관리',
          routeName: '등록상품관리',
          link: 'registeredProduct',
          image: 'registProduct'
        },
        {
          name: '재고 연동',
          routeName: '재고연동',
          link: 'inventoryLinked',
          image: 'stockLinakage'
        },
        {
          name: '주문 관리',
          routeName: '주문관리',
          link: 'ordermanagement',
          image: 'orderManagement'
        },
        {
          name: '통계 분석',
          routeName: '통계분석',
          link: 'statistics',
          image: 'statistics'
        },
        {
          name: '이미지 템플릿',
          routeName: '이미지 템플릿',
          link: 'template/image',
          image: 'imageTemplate'
        },
        {
          name: '업로드 템플릿',
          routeName: '업로드 템플릿',
          link: 'template/upload',
          image: 'uploadTemplate'
        },
        {
          name: '단어 관리 템플릿',
          routeName: '단어 관리 템플릿',
          link: 'template/word',
          image: 'wordTemplate'
        },
        {
          name: '마켓 설정 템플릿',
          routeName: '마켓 설정 템플릿',
          link: 'template/market',
          image: 'marketTemplate'
        },
        { name: '환경 설정', routeName: '환경설정', link: 'preferences', image: 'settings' },
        {
          name: '고객센터',
          routeName: '#',
          link: 'https://pf.kakao.com/_enpxkb/chat',
          image: 'managementCenter'
        },
        {
          name: 'OMarket 오픈카톡',
          routeName: '#',
          link: '',
          image: 'kakaotalk'
        },
        {
          name: 'OMarket 카페',
          routeName: '#',
          link: '',
          image: 'naverCafe'
        },
        {
          name: '사용자 가이드',
          routeName: '#',
          link: 'https://kind-trowel-a78.notion.site/VIDOS-99e22c7d4f26490989227cecbedd691e',
          image: 'userGuide'
        }
      ]
    };
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/sidebar.scss';
</style>